<template>
	<div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12" lg="12">
                    <b-form-group :label="$t('name')">
                        <ValidationProvider name="name" rules="required" v-slot="{valid, errors}">
                            <b-input-group class="mb-1" >
                                <b-form-input v-model="formData.name"
                                              :state="errors[0] ? false : null"/>
                            </b-input-group>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"/>
                        </ValidationProvider>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="4">
                    <ValidationProvider name="type" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('university_type')">
                            <parameter-selectbox
                                :validate-error="errors[0]"
                                code="university_types"
                                v-model="formData.type"
                            ></parameter-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="4">
                    <ValidationProvider name="country_id" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('country')">
                            <country-selectbox
                                :validate-error="errors[0]"
                                :state="errors[0] ? false : null"
                                v-model="formData.country_id">
                            </country-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="4">
                    <ValidationProvider name="status" rules="" v-slot="{valid, errors}">
                        <b-form-group :label="$t('status')">
                            <status-selectbox
                                :validate-error="errors[0]"
                                :state="errors[0] ? false : null"
                                v-model="formData.status">
                            </status-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>

                <b-col md="12">
                    <ValidationProvider name="old_id" rules="" v-slot="{valid, errors}">
                        <b-form-group :label="$t('old_name_if_exists')">
                            <university-selectbox
                                :validate-error="errors[0]"
                                :state="errors[0] ? false : null"
                                v-model="formData.old_id">
                            </university-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>

            </b-row>

            <div class="d-flex justify-content-center mt-2">
                <b-button variant="primary"
                          @click="createForm"
                          :disabled="formLoading">
                    {{ $t('save') }}
                </b-button>
            </div>
        </ValidationObserver>
	</div>
</template>

<script>
	// Components
    import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";

	// Services
    import UniversityService from "@/services/UniversityService";
	// Other
	import {ValidationProvider, ValidationObserver} from "vee-validate"
    import CountrySelectbox from "@/components/interactive-fields/CountrySelectbox";
    import StatusSelectbox from "@/components/interactive-fields/StatusSelectbox";
    import UniversitySelectbox from "@/components/interactive-fields/UniversitySelectbox";

	export default {
		components: {
            UniversitySelectbox,
            StatusSelectbox,
            CountrySelectbox,
			ValidationProvider,
			ValidationObserver,
            ParameterSelectbox
		},
		data() {
			return {
				formData: {
					name:null,
                    country_id:null,
                    old_id:null,
                    status:null,
                    type:null

				},
				formLoading: false,
			}
		},
		methods: {
			async createForm() {
				const isValid = await this.$refs.formModalValidate.validate();
				if (isValid) {
					this.formLoading = true;
					UniversityService.store(this.formData)
					             .then(response => {
						             this.$toast.success(this.$t('api.' + response.data.message));
						             this.$emit('createFormSuccess', true);
					             })
					             .catch(e => {
						             this.showErrors(e, this.$refs.formModalValidate);
					             })
					             .finally(() => {
						             this.formLoading = false
					             })
				}
			}
		}
	}
</script>

